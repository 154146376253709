<template>
  <div>
    <!-- 公司资料 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Corporate Information")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Investor Relations</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/investors/default">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Corporate Information") }} -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
      <!-- <div class="loading_box" v-if="showLoading"></div>
      <div class="container gov-link">
        <div v-for="item in dataList" :key="item.id">
          <h2>{{ filterLang(item, "Title") }}</h2>
          <a
            v-if="item.addressType == 'jump_url'"
            :href="filterLang(item, 'JumpUrl')"
            target="_blank"
            >{{ filterLang(item, "Content") }}</a
          >
          <a
            v-if="item.addressType == 'pdf_file'"
            :href="filterLang(item, 'FileUrl')"
            target="_blank"
            >{{ filterLang(item, "Content") }}</a
          >
          <br />
          <br />
          <br />
        </div>
      </div> -->
    </section>
    <div
      style="
        margin: 3em auto 0 auto;
        padding: 0 0.5em;
        width: 100%;
        max-width: 1200px;
      "
      v-if="false"
    >
      <div class="container ir-info">
        <ul>
          <!-- 资料列表 -->
          <div class="loading_box" v-if="showLoading"></div>
          <li>
            <div v-for="item in dataList" :key="item.id">
              <h2>{{ filterLang(item, "Title") }}</h2>
              <!-- 14px/2 Arial, Helvetica, sans-serif -->
              <!-- en_contaier_p:this.$store.state.nowlanguage === 'en' -->
              <!-- :class="[(this.$store.state.nowlanguage === 'en') ? 'en_container_p':'container_p']" -->
              <p class="container_p">
                <span v-html="filterLang(item, 'Content')"></span>
              </p>
            </div>
          </li>
          <!--右侧列表  -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ZiliList } from "@/api/investors";
import { filterLang } from "@/utils/LangChange";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [ifarmeMixin],
  methods: {
    filterLang,
  },
  data() {
    return {
      dataList: [],
      showLoading: true,
      banner: "",
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_info.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_info.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_info.php",
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.GONGSIZILIAO,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    ZiliList().then((res) => {
      this.showLoading = false;
      this.dataList = res.result;
    });
  },
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped>
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 1.5em 1.5em 1.5em;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article div {
  display: flex;
}

article div div {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 48%;
}

article div div h2 {
  color: #165da3;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.8em;
}

article div div p {
  color: #221815;
  font-size: 1em;
  line-height: 1.8em;
  margin-bottom: 0.9em;
}

article div div:nth-child(1) p {
  width: 60%;
}

#iframeContainer {
  margin: 3em auto 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
}

iframe {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
  }

  article {
    padding: 0 1.5em 1.5em 1.5em;
  }
}
@media screen and (max-width: 550px) {
  .ir-info ul li {
    flex-direction: column;
  }
  .ir-info ul li div {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  article div {
    flex-direction: column;
  }

  article div div {
    width: 96%;
  }

  article div div:nth-child(1) p {
    width: 100%;
  }
}
.ir-info ul {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
  /* margin: 3em auto 0 auto !important; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1024px;
  margin: auto;
}
.ir-info ul li {
  padding-right: 20%;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.ir-info ul li div {
  width: 50%;
}
.ir-info ul li:last-child {
  padding-right: 0;
}
.container h2 {
  font-size: 1rem;
  color: #f00;
  padding-bottom: 10px;
  font-weight: 700;
}
.container_p {
  color: #666666;
  /* 中文字体 */
  font: 14px/2 "宋体", Arial;
  padding-bottom: 30px;
}
.en_container_p {
  color: #666666;
  /* 英文字体 */
  font: 14px/2 Arial, Helvetica, sans-serif;
  padding-bottom: 30px;
}
</style>
